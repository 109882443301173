<template>
    <div id="settings-wrapper">
        <!-- Info -->
        <v-snackbar top :color="info.color" v-model="showInfo">
            <v-icon>{{ info.icon }}</v-icon>
            <span class="font-weight-bold pl-2">{{ info.message }}</span>
        </v-snackbar>
        <!-- downloadalert  -->
        <v-snackbar width="300px" vertical top v-model="showDownloadProgress" :timeout="2000000" color="primary">
            <div class="pb-0">
                <div class="download-label">
                    <span class="mx-auto">generating export...</span>
                </div>
                <v-progress-linear style="width: 310px" label="downloading" color="white" indeterminate rounded height="6"></v-progress-linear>
            </div>
        </v-snackbar>
        <!-- Added Info -->
        <v-snackbar top v-model="showUpdateMessage" color="#16c96d">
            <v-icon>mdi-check-circle-outline</v-icon><span class="font-weight-bold pl-2"> The selected keywords have been added to your selected research! </span>
        </v-snackbar>
        <v-snackbar top v-model="showAddMessage" color="#16c96d">
            <v-icon>mdi-check-circle-outline</v-icon><span class="font-weight-bold pl-2"> The selected keywords have been saved as a new research! </span>
        </v-snackbar>
        <v-snackbar top v-model="showDeleteMessage" color="#16c96d">
            <v-icon>mdi-check-circle-outline</v-icon><span class="font-weight-bold pl-2"> The selected keywords have been deleted! </span>
        </v-snackbar>
        <!-- different locations in clipboard Info -->
        <v-snackbar top color="#fcba03" v-model="showLocError">
            <v-row>
                <v-col cols="1"><v-icon class="pl-3">mdi-alert-circle-outline</v-icon> </v-col>
                <v-col>
                    <span class="font-weight-bold">You have keywords in your clipboard with searchvolume data from a different location. Please delete these keywords first. </span>
                </v-col>
            </v-row>
        </v-snackbar>
        <v-card id="settings" class="py-2 px-4 right_nav_btn" color="primary" dark flat link :style="'position: fixed; top: 115px; right: -35px; border-radius: 25px; z-index: 4; opacity: 0.7;'">
            <v-icon color="mr-10" large> mdi-text-box-plus </v-icon>
        </v-card>
        <v-menu
            v-model="sidebarmenu"
            :close-on-content-click="false"
            activator="#settings"
            bottom
            content-class="v-settings"
            left
            :close-on-click="!tour_running"
            nudge-left="8"
            offset-x
            origin="top right"
            transition="scale-transition">
            <v-card class="text-center mb-0" width="300" id="clipboard">
                <v-card-text v-if="keywords.length === 0">
                    <strong class="d-inline-block" id="maintitle"> Clipboard </strong>
                    <hr class="my-3" id="divider" />
                    <v-alert type="info" color="primary">
                        <b class="infotext"
                            ><p>You can add keywords to the Clipboard from everywhere!</p>
                            <p class="infotext">Use this button to insert keywords:</p>
                        </b>
                        <div>
                            <v-btn fab dark small color="grey" title="add keyword(s) to collection" class="fabicon btnpadding">
                                <v-icon dark>mdi-text-box-plus</v-icon>
                            </v-btn>
                        </div>
                    </v-alert>
                </v-card-text>
                <v-card-text v-else>
                    <strong class="mb-3 d-inline-block" id="maintitle"> Clipboard </strong>
                    <v-data-table v-bind="tblOptions" :headers="headers" :items="keywords" height="inherit" item-key="keyword" :items-per-page="perpage" dense>
                        <template v-slot:item.keyword="{ item }">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <div v-on="on">{{ shortenKeyword(item.keyword) }}</div>
                                    <!-- add to collection -->
                                </template>
                                <span>{{ item.keyword }}</span>
                            </v-tooltip>
                        </template>
                        <template v-slot:item.avgsv="{ item }">
                            <v-chip small color="primary">{{ $helpers.formatNumber(item.avgsv) }}</v-chip>
                        </template>
                        <template v-slot:item.action="{ item }">
                            <v-icon small @click="deleteItem(item)"> mdi-close </v-icon>
                        </template>
                    </v-data-table>
                    <hr class="my-3" id="divider" />
                    <!-- action buttons -->
                    <div class="actionBtns">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class="mr-5" dark fab color="primary" @click="downloadDialog = true" small v-bind="attrs" v-on="on">
                                    <v-icon dark>mdi-cloud-download</v-icon>
                                </v-btn>
                            </template>
                            <span>download keywords</span>
                        </v-tooltip>
                        <!-- save new collection -->
                        <v-tooltip top v-if="$helpers.isActionAllowed(6, user_rights)">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn id="save_btn" class="mr-5" color="primary" small fab dark @click="dialog = true" v-bind="attrs" v-on="on">
                                    <v-icon dark>mdi-content-save</v-icon>
                                </v-btn>
                            </template>
                            <span>save new collection</span>
                        </v-tooltip>
                        <!-- add to collection -->
                        <v-tooltip top v-if="$helpers.isActionAllowed(7, user_rights)">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class="mr-5" color="primary" fab small dark @blur="getExistingLists" @click="researchDialog = true" v-bind="attrs" v-on="on">
                                    <v-icon dark>mdi-file-plus</v-icon>
                                </v-btn>
                            </template>
                            <span>add to an existing collection</span>
                        </v-tooltip>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary" fab small dark @blur="getExistingLists" @click="deleteQuestion = true" v-bind="attrs" v-on="on">
                                    <v-icon dark>mdi-delete</v-icon>
                                    <!-- add to collection -->
                                </v-btn>
                            </template>
                            <span>delete all keywords</span>
                        </v-tooltip>
                    </div>
                    <!--save dialog -->
                    <v-dialog v-model="dialog" scrollable max-width="350px">
                        <!-- <template v-slot:activator="{ on }">
                  <v-btn color='primary' v-on="on" block>save new research</v-btn>
                </template> -->
                        <v-card>
                            <strong id="title" class="mb-3 d-inline-block">Save Collection</strong>
                            <v-card-text id="savebody">
                                <v-container fluid grid-list-xl id="kwpform">
                                    <v-layout align-center wrap>
                                        <v-flex xs12 id="kwpformflex">
                                            <span class="existMessage">
                                                {{ existMessage }}
                                            </span>
                                            <v-text-field id="collection_name" label="Collection Name" v-model="documentName" prepend-icon="mdi-file-document" @focus="disableExistMessage" />
                                        </v-flex>
                                        <!-- <v-flex xs12 id="kwpformflex">
                                    <v-select
                                        v-model="collectionType"
                                        :items="types"
                                        item-text="value"
                                        :menu-props="{'maxHeight':150 }"
                                        label="collection type"
                                        prepend-icon="mdi-format-list-bulleted-type"
                                        return-object
                                        overflow
                                        attach>
                                    </v-select>
                                </v-flex> -->
                                        <v-flex xs12 id="kwpformflex">
                                            <v-select
                                                v-model="selectedLocation"
                                                :items="searchvolumeFromItems"
                                                item-text="loc"
                                                :menu-props="{ maxHeight: 150 }"
                                                label="country for searchvolume"
                                                prepend-icon="mdi-map-marker"
                                                return-object
                                                disabled>
                                            </v-select>
                                        </v-flex>
                                        <v-flex xs12 id="kwpformflex">
                                            <!-- <v-switch
                                        v-model="publish"
                                        color= "info"
                                        :label="switchlbl"
                                  ></v-switch> -->
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                            </v-card-text>
                            <v-divider />
                            <v-card-actions>
                                <v-btn id="final_save_collecion" color="primary" :loading="btndisabled" dark @click="prepareSaveResearch(keywords)" block>save collection</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <!-- Download Dialog -->
                    <v-dialog v-model="downloadDialog" max-width="350px" id="downloaddialog">
                        <v-card>
                            <center>
                                <strong id="title" class="mb-3 d-inline-block">Download Research</strong>
                            </center>
                            <v-card-text class="pb-0">
                                <v-select
                                    class="mr-3"
                                    v-model="doctype"
                                    :items="doctypes"
                                    filled
                                    item-text="value"
                                    item-value="data"
                                    return-object
                                    :prepend-icon="doctype.icon"
                                    label="select file format"
                                    attach></v-select>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn class="mx-auto" rounded dark color="primary" v-on:click="download()">Download</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <!-- add to research dialog -->
                    <v-dialog v-model="researchDialog" max-width="350px" class="text-center">
                        <v-card>
                            <strong id="title2" class="mb-3 d-inline-block text-center">Add to research</strong>
                            <v-card-text>
                                <v-select
                                    v-model="selectedList"
                                    :items="existLists"
                                    item-text="listname"
                                    label="Available researches"
                                    prepend-icon="mdi-format-list-bulleted"
                                    return-object
                                    class="pt-5 pb-5"
                                    id="addtocollection">
                                </v-select>
                            </v-card-text>
                            <v-divider />
                            <v-card-actions>
                                <v-btn color="primary" :disabled="btndisabled" dark @click="addToResearch" block>add keywords to research</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <!-- Delete Dialog List -->
                    <v-dialog v-model="deleteQuestion" max-width="350px">
                        <v-card>
                            <v-card-title
                                style="
                                     {
                                        text-align: center;
                                    }
                                ">
                                Are you sure?</v-card-title
                            >
                            <v-card-text class="text-center">
                                <v-btn class="mr-3" text absolute left @click="deleteQuestion = false"> Nevermind </v-btn>
                                <v-btn color="primary" text @click="deleteList()"> Yes </v-btn>
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                    <!-- Delete Dialog Item -->
                    <!-- <v-dialog v-model="deleteQuestionItem" max-width="300">
            <v-card>
              <v-card-title style="{text-align: center}"> Are you sure?</v-card-title>
              <v-card-text class="text-center">
                <v-btn class="mr-3" text @click="deleteQuestionItem = false"> Nevermind </v-btn>
                <v-btn color="#42DEFA" text @click="deleteItem()"  >  Yes </v-btn>
              </v-card-text>
            </v-card>
          </v-dialog> -->
                    <!-- Keep Keywords in List Dialog -->
                    <v-dialog v-model="keepKeywordsDialog" max-width="350">
                        <v-card>
                            <v-card-title
                                style="
                                     {
                                        text-align: center;
                                    }
                                ">
                                Keep keywords in clipboard?</v-card-title
                            >
                            <v-card-text class="text-center">
                                <v-btn
                                    class="mr-3"
                                    text
                                    @click="
                                        keywords = []
                                        keepKeywordsDialog = false
                                        $store.dispatch('SET_CLIPBOARD_HEADERS', [])
                                    ">
                                    No
                                </v-btn>
                                <v-btn id="keppKeywordsBtn" color="primary" text @click="keepKeywordsDialog = false"> Yes </v-btn>
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                </v-card-text>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
    // Mixins
    import { onlyKeywords } from "./helper/utils"
    import Proxyable from "vuetify/lib/mixins/proxyable"
    import { mapMutations, mapState } from "vuex"
    // import DownloadDialog from "./DownloadDialog.vue";

    export default {
        name: "DashboardCoreSettings",
        // components: {
        //   DownloadDialog
        // },
        mixins: [Proxyable],

        data: () => ({
            // get selectedLocation() {
            //     if (localStorage.selectedLocation) {
            //         return JSON.parse(localStorage.selectedLocation)
            //     } else {
            //         return { loc: "Germany", value: "2276", cc: "de" }
            //     }
            // },
            // get keywords() {
            //     // console.log(localStorage)
            //     // if(localStorage.getItem('keywords') == "undefined"){
            //     //   localStorage.setItem("keywords",[]);
            //     // }
            //     if (localStorage.getItem("keywords") && localStorage.getItem("keywords") != "undefined") {
            //         if (localStorage.getItem("keywords") != "[object Object],[object Object]") {
            //             return JSON.parse(localStorage.getItem("keywords"))
            //         } else {
            //             return []
            //         }
            //     } else {
            //         return []
            //     }
            // },

            // set keywords(value) {
            //     localStorage.setItem("keywords", JSON.stringify(value))
            // },
            // get downloadheaders() {
            //   if (localStorage.getItem('downloadheaders')) {
            //     if (localStorage.getItem('downloadheaders') != "[object Object],[object Object]") {
            //       return JSON.parse(localStorage.getItem('downloadheaders'));
            //     }else{
            //       return[]
            //     }
            //   } else {
            //     return [];
            //   }
            // },
            collectionType: { value: "Research", data: "research" },
            types: [
                { value: "Research", data: "research" }
                // { value: "Rankings", data: "rankings"}
            ],
            doctypes: [
                {
                    value: "Microsoft Excel (.xlsx)",
                    data: "json2xls",
                    icon: "mdi-microsoft-excel"
                },
                {
                    value: "Comma separated values (.csv)",
                    data: "json2csv",
                    icon: "mdi-file-delimited"
                }
            ],
            doctype: {
                value: "Microsoft Excel (.xlsx)",
                data: "json2xls",
                icon: "mdi-microsoft-excel"
            },
            downloadDialog: false,
            tblOptions: {
                "footer-props": {
                    "items-per-page-options": [5, 10, 15]
                }
            },
            tblheight: 150,
            perpage: 10,
            switchlbl: "don´t publish",
            auth: "Basic " + window.btoa(process.env.VUE_APP_AUTH),
            apiUrl: process.env.VUE_APP_APIURL,
            documentName: "",
            // searchvolumeFrom: localStorage.selectedLocation ?  JSON.parse(localStorage.selectedLocation) : { loc: "Germany", value: "2276", cc:"de" },
            searchvolumeFromItems: [
                { loc: "France", value: "2250" },
                { loc: "Spain", value: "2724" },
                { loc: "Netherlands", value: "2528" },
                { loc: "Germany", value: "2276" },
                { loc: "Austria", value: "2040" },
                { loc: "Switzerland", value: "2756" },
                { loc: "United Kingdom", value: "2826" },
                { loc: "Italy", value: "2380" },
                { loc: "Poland", value: "2616" },
                { loc: "Russia", value: "2643" },
                { loc: "Croatia", value: "2191" },
                { loc: "Czechia", value: "2203" },
                { loc: "Slovakia", value: "2703" },
                { loc: "Slovenia", value: "2705" }
            ],
            publish: true,
            existMessage: "",
            existLists: [],
            selectedList: {},
            dialogm1: "",
            dialog: false,
            researchDialog: false,
            listkeywords: [],
            headers: [
                {
                    text: "Keyword",
                    align: "left",
                    sortable: true,
                    value: "keyword"
                },
                {
                    text: "Vol.",
                    value: "avgsv",
                    align: "left",
                    sortable: true
                },
                { text: "", value: "action", sortable: false }
            ],
            color: "#00CAE3",

            menu: false,
            keepKeywordsDialog: false,
            showAddMessage: false,
            showUpdateMessage: false,
            showDeleteMessage: false,
            deleteQuestion: false,
            deleteQuestionItem: false,
            selectedItem: [],
            showLocError: false,
            btndisabled: false,
            info: {
                message: null,
                icon: null,
                color: null
            },
            showInfo: false,
            showDownloadProgress: false
        }),

        computed: {
            keywords: {
                // getter
                get: function () {
                    return this.$store.state.clipboard_keywords
                },
                // setter
                set: function (newValue) {
                    this.$store.dispatch("SET_CLIPBOARD_KEYWORDS", newValue)
                }
            },
            tour_running() {
                return this.$store.state.tour_running
            },
            ...mapState(["barImage"]),
            sidebarmenu: {
                get() {
                    return this.$store.state.sidebarMenu
                },
                set(value) {
                    this.$store.dispatch("CHANGE_SIDEBARMENU")
                }
            },
            ...mapState(["user_rights"]),
            selectedLocation() {
                return this.$store.state.clipboard_location
            }
        },

        watch: {
            publish(val) {
                if (val === true) this.switchlbl = "publish"
                else this.switchlbl = "don´t publish"
            },
            "$store.state.open_save_collection_dialog": {
                // - TOUR
                immediate: true,
                handler(val) {
                    if (val) {
                        this.dialog = true
                        this.$store.dispatch("UNSET_TOUR_VARIABLES")
                    }
                }
            },
            "$store.state.set_collection_name": {
                // - TOUR
                immediate: true,
                handler(val) {
                    if (val) {
                        if (this.documentName.length == 0) {
                            this.documentName = "Sneaker - Test Collection"
                        }
                        this.$store.dispatch("UNSET_TOUR_VARIABLES")
                    }
                }
            },
            "$store.state.save_collection": {
                // - TOUR
                immediate: true,
                async handler(val) {
                    if (val) {
                        let created = await this.prepareSaveResearch(this.keywords)
                        console.log(created)
                        if (!created) {
                            let randomNumber = Math.floor(Math.random() * 100)
                            this.documentName = "Sneaker - Test Collection - " + randomNumber
                            console.log(this.documentName)
                            this.prepareSaveResearch(this.keywords)
                        }
                        this.$store.dispatch("UNSET_TOUR_VARIABLES")
                    }
                }
            },
            "$store.state.skip_keep_keywords": {
                // - TOUR
                immediate: true,
                handler(val) {
                    if (val) {
                        setTimeout(() => {
                            this.keepKeywordsDialog = false
                        }, 500)
                    }
                }
            }
        },

        methods: {
            shortenKeyword(keyword) {
                if (keyword.length > 15) {
                    let temp = keyword.slice(0, 15)
                    return temp + "..."
                } else {
                    return keyword
                }
            },
            download() {
                this.downloadDialog = false
                this.showDownloadProgress = true

                let keywords = []
                let headers = this.$store.state.clipboard_headers

                keywords = JSON.parse(JSON.stringify(this.keywords))

                console.log(keywords)
                try {
                    this.$matomo.trackEvent("Clipboard", "download", "Keywords length: " + keywords.length, "")
                } catch (error) {
                    console.log(error)
                    console.log("matomo blocked")
                }
                keywords.sort(function (a, b) {
                    return b.avgsv - a.avgsv
                })
                let fields = this.$helpers.deleteDownloadColumns(headers.modifiedHeaders)
                let foundHeaders = headers.modifiedHeaders.find((x) => x.custom == true)
                console.log(foundHeaders)
                if (foundHeaders) fields = this.$helpers.addCustoms(keywords, fields)
                console.log(fields)
                if (this.doctype.data == "json2xls") {
                    keywords = this.$helpers.transformDataForExcelDownload(keywords, headers.modifiedHeaders)
                }
                if (this.doctype.data == "json2csv") {
                    keywords = keywords.map((x) => {
                        x.cats = x.cats ? x.cats[0][x.cats[0].length - 1].category_name : null // only one cat for donwload
                        x.keyword_difficulty = x.keyword_difficulty ? x.keyword_difficulty.difficulty : null
                        if (x.serp_items) {
                            let serpString = ""
                            x.serp_items.map((y, i) => (serpString += y.value += i == x.serp_items.length - 1 ? "" : ","))
                            x.serp_items = serpString
                        } else {
                            x.serp_items = null
                        }
                        return x
                    })
                }
                this.$http
                    .post(
                        process.env.VUE_APP_APIURL + "/research/" + this.doctype.data,
                        { data: this.$helpers.deleteDownloadAttributes(keywords), columns: fields },
                        { headers: { Authorization: this.auth } }
                    )
                    .then(function (response) {
                        window.location = process.env.VUE_APP_APIURL + "/" + response.body
                        this.showDownloadProgress = false
                        // this.downloaded = true;
                    })
            },
            getIndexOfHeader(header, headers) {
                //  console.log(header)
                for (let index = 0; index < headers.length; index++) {
                    if (header.text == headers[index].text) {
                        return true
                    }
                }
                return false
            },
            deleteList() {
                this.keywords = []
                this.showDeleteMessage = true
                this.deleteQuestion = false

                //delete store clipboard headers
                this.$store.dispatch("SET_CLIPBOARD_HEADERS")
                // console.log(this.$store.state.clipboard_headers)s
            },
            test() {
                // console.log(localStorage.getItem('keywords'))
            },
            getTableHeight() {
                return 150
            },
            // prepareDeleteItem(item){
            //   this.selectedItem = item;
            //   this.deleteQuestionItem = true;
            // },
            deleteItem(item) {
                let kwIndex = this.keywords.findIndex((x) => x.keyword == item.keyword)
                // console.log(kwIndex)
                let kw = this.keywords
                kw.splice(kwIndex, 1)
                // localStorage.setItem("keywords", JSON.stringify(kw))
                // this.$store.dispatch("CHANGE_SIDEBARMENU")
                // this.$store.dispatch("CHANGE_SIDEBARMENU")
            },
            disableExistMessage: function () {
                this.existMessage = ""
            },
            checkIfExists: function () {
                return new Promise((resolve, reject) => {
                    if (this.documentName != "") {
                        this.$http
                            .post(this.apiUrl + "/research/namecheck", { name: this.documentName, company_id: localStorage.company_id }, { headers: { Authorization: this.auth } })
                            .then(function (response) {
                                if (response.body.error) {
                                    this.existMessage = "That name already exists, please try something else"
                                    this.documentName = ""
                                    resolve(false)
                                } else {
                                    resolve(true)
                                }
                            })
                    } else {
                        this.existMessage = "Please insert a name for your Collection"
                        this.documentName = ""
                        resolve(false)
                    }
                })
            },
            checkCredits: function (kw_count) {
                return new Promise((resolve, reject) => {
                    this.$http
                        .post(this.apiUrl + "/research/creditsavailable", { company_id: localStorage.company_id, kw_count: kw_count }, { headers: { Authorization: this.auth } })
                        .then(function (response) {
                            console.log(response)
                            if (!response.body.credits_left) {
                                resolve(false)
                            } else {
                                resolve(true)
                            }
                        })
                })
            },
            getExistingLists() {
                this.$http
                    .post(
                        this.apiUrl + "/research/availablelists",
                        {
                            type: "research",
                            owner: localStorage.id,
                            company_id: localStorage.company_id
                        },
                        { headers: { Authorization: this.auth } }
                    )
                    .then(function (response) {
                        console.log(response.body)
                        this.existLists = response.body.sort((a, b) => a.listname.localeCompare(b.listname))
                    })
            },
            async prepareSaveResearch(keywords) {
                this.btndisabled = true
                if (/\s$/.test(this.documentName)) {
                    this.documentName = this.documentName.replace(/^[ ]+|[ ]+$/g, "")
                }
                let credits_left = await this.checkCredits(this.$helpers.removeDuplicatesByAttribute(keywords, "keyword").length)
                if (!credits_left) {
                    this.existMessage = "You dont have enough credits left."
                    return false
                }
                var checkIfExists = await this.checkIfExists()
                if (checkIfExists) {
                    // check if name has no / & %
                    var validString = true
                    if (this.documentName.indexOf("%") > -1) validString = false
                    if (this.documentName.indexOf("/") > -1) validString = false
                    if (this.documentName.indexOf("&") > -1) validString = false
                    if (validString && this.documentName.length > 3) {
                        // //console.log("valid")
                        this.createResearch(keywords)
                        return true
                    } else {
                        this.btndisabled = false
                        this.existMessage = "Please use a proper name. Minimum 4 characters and none of [%, /, &]."
                        // this.documentName = ""
                        return false
                    }
                } else {
                    this.btndisabled = false
                    return false
                }
            },

            endSaveAndUpdate() {
                this.documentName = ""
                this.dialog = false
                this.$store.dispatch("CHANGE_SIDEBARMENU")
                // this.keywords = [];
                this.researchDialog = false
                this.keepKeywordsDialog = true
                this.btndisabled = false
            },

            async addToResearch() {
                this.btndisabled = true
                let credits_left = await this.checkCredits(this.$helpers.removeDuplicatesByAttribute(this.keywords, "keyword").length)
                if (!credits_left) {
                    this.createInfo("You dont have any credits left. Please contact the support via chat or email!", "#fcba03", "mdi-alert-circle-outline")
                    this.btndisabled = false
                    return false
                }
                this.$http
                    .post(
                        this.apiUrl + "/research/listdata",
                        {
                            listname: this.selectedList.listname,
                            company_id: localStorage.company_id
                        },
                        { headers: { Authorization: this.auth } }
                    )
                    .then((response) => {
                        console.log(response.body[0].count)
                        console.log(this.keywords.length)
                        console.log("new", response.body[0].mdata.langloc_new)
                        console.log("selected", this.selectedLocation)
                        console.log(response.body[0].mdata.langloc_new == this.selectedLocation)
                        if (response.body[0].mdata.status !== "updating" && response.body[0].mdata.status !== "creating") {
                            if (
                                response.body[0].mdata.langloc_new.value == this.selectedLocation.value &&
                                response.body[0].mdata.langloc_new.loc == this.selectedLocation.loc &&
                                response.body[0].mdata.langloc_new.ads_lang_code == this.selectedLocation.ads_lang_code &&
                                response.body[0].mdata.langloc_new.serp_lang_code == this.selectedLocation.serp_lang_code
                            ) {
                                if (response.body[0].count + this.keywords.length <= 10000) {
                                    let data = {
                                        keywords: this.$helpers.removeDuplicatesByAttribute(this.keywords, "keyword"),
                                        attributes: {
                                            name: this.selectedList.listname,
                                            user: localStorage.id,
                                            type: this.collectionType.data,
                                            shared: this.publish,
                                            editable: true,
                                            lang: this.selectedLocation.lang,
                                            loc: this.selectedLocation.value,
                                            value: this.selectedLocation.loc,
                                            headers: this.$store.state.clipboard_headers
                                        },
                                        company_id: localStorage.company_id,
                                        socket_id: this.$socket.id
                                    }
                                    this.$http
                                        .post(this.apiUrl + "/research/keywords/add", data, {
                                            headers: { Authorization: this.auth }
                                        })
                                        .then((response) => {
                                            if (response.status == 200) {
                                                this.endSaveAndUpdate()
                                            } else {
                                                this.addToResearch()
                                            }
                                        })
                                } else {
                                    this.createInfo("Collection limit is 10.000 keywords. Selected collection size is: " + response.body[0].count, "#fcba03", "mdi-alert-circle-outline")
                                    this.btndisabled = false
                                }
                            } else {
                                this.createInfo(
                                    "The keywords in your clipboard have search volume of another country. Please select a collection with the same country!",
                                    "#fcba03",
                                    "mdi-alert-circle-outline"
                                )
                                this.btndisabled = false
                            }
                        } else {
                            this.createInfo("This research is currently being updated. Please try again in a few moments!", "#fcba03", "mdi-alert-circle-outline")
                            this.btndisabled = false
                        }
                    })
            },
            createInfo(message, color, icon) {
                this.info.message = message
                this.info.color = color
                this.info.icon = icon
                this.showInfo = true
            },
            createResearch(keywords) {
                this.showAddMessage = true
                console.log(keywords)

                let data = {
                    keywords: this.$helpers.removeDuplicatesByAttribute(keywords, "keyword"),
                    attributes: {
                        name: this.documentName,
                        user: localStorage.id,
                        type: this.collectionType.data,
                        shared: this.publish,
                        editable: true,
                        lang: this.selectedLocation.lang,
                        loc: this.selectedLocation.value,
                        value: this.selectedLocation.loc,
                        langloc_new: this.selectedLocation,
                        headers: this.$store.state.clipboard_headers
                    },
                    company_id: localStorage.company_id,
                    socket_id: this.$socket.id
                }
                this.$http
                    .post(this.apiUrl + "/research/create", data, {
                        headers: { Authorization: this.auth }
                    })
                    .then((response) => {
                        // console.log(response.status)
                        if (response.status == 200) {
                            this.endSaveAndUpdate()
                        } else {
                            this.createResearch(keywords)
                        }
                    })
            }
        },
        mounted() {
            this.$socket.on("reloadAvailableLists", (data) => {
                console.log("Reloading avalable Lists")
                this.getExistingLists()
            })
        },
        updated() {
            // console.log(this.keywords)
        }
    }
</script>
<style scoped>
    .v-btn {
        margin-top: 10px;
    }
    .existMessage {
        color: red;
    }
    #maintitle {
        padding-top: 3px;
        line-height: 25px;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        text-align: center !important;
        font-size: 1em;
    }
    #title {
        padding-top: 16px;
        line-height: 25px;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        text-align: center !important;
        color: #212529;
        font-size: 1em;
    }
    #savebody {
        padding-top: 0%;
    }
    #kwpformflex {
        padding-top: 0%;
    }
    #heading {
        font-size: 1.2em;
    }
    .actionBtns {
        margin-top: -10px !important;
    }
    #divider {
        border: 1px solid rgb(231, 231, 231);
        border-radius: 1px;
    }
    .btnpadding {
        margin-right: 50px;
    }
    #title2 {
        padding-top: 16px;
        line-height: 25px;
        font-size: 12px;
        margin-left: 30%;
        font-weight: 600;
        text-transform: uppercase;
        text-align: center !important;
        color: #212529;
        font-size: 1em;
    }
    #addtocollection {
        padding-top: 15px;
        padding-bottom: 15px;
    }
    .download-label {
        font-weight: bold;
        padding-bottom: 5px;
        display: flex;
    }
    #settings {
        /* top */
    }
</style>
<style lang="sass" scoped>
    .v-settings
      .v-item-group > *
        cursor: pointer

      &__item
        border-width: 3px
        border-style: solid
        border-color: transparent !important

        &--active
          border-color: #00cae3 !important
    .infotext
      text-align: left

    .v-data-footer__select
      display: none
</style>
